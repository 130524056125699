<template>
  <div class="agree-containter">
    <div>
      <p style="text-align: center;font-weight: bold;font-size: 24px;margin: 20px auto;">用户注册及使用网站隐私协议</p>
          <p>
        在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户注册及使用余干县城投数字服务平台隐私协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定余干县城投数字服务平台房地产市场经营管理有限公司（以下简称“余干县城投数字服务平台”）与用户之间关于余干县城投数字服务平台网站服务（以下简称“服务”）的权利义务。用户，是指注册、登录、使用本服务的个人或组织。本协议可由余干县城投数字服务平台随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网站中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用余干县城投数字服务平台提供的服务，用户继续使用余干县城投数字服务平台提供的服务将被视为接受修改后的协议。
      </p>
          <p class="title">一、账号注册</p>
          <p>
        1、用户在使用本服务前需要注册一个余干县城投数字服务平台网站账号。余干县城投数字服务平台网站账号应当使用手机号码绑定注册，请用户使用尚未与余干县城投数字服务平台网站账号绑定的手机号码，以及未被余干县城投数字服务平台根据本协议封禁的手机号码注册余干县城投数字服务平台网站账号。余干县城投数字服务平台可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
      </p>
          <p>2、鉴于余干县城投数字服务平台网站账号的绑定注册方式，您同意余干县城投数字服务平台在注册时将允许您的手机号码等信息用于注册。</p>
          <p>
        3、在用户注册及使用本服务时，余干县城投数字服务平台需要搜集能识别用户身份的企业信息以便余干县城投数字服务平台可以在必要时联系用户，或为用户提供更好的使用体验。余干县城投数字服务平台搜集的信息包括但不限于企业的名称、地址、法人名字、法人身份证等；余干县城投数字服务平台同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
      </p>
          <p class="title">二、用户个人隐私信息保护</p>
          <p>
        1、如果余干县城投数字服务平台发现或收到他人举报或投诉用户违反本协议约定的，余干县城投数字服务平台有权不经通知随时对相关内容，包括但不限于用户资料、发布记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、设备封禁、功能封禁的处罚，且通知用户处理结果。
      </p>
          <p>2、因违反用户协议被封禁的用户，可以自行与余干县城投数字服务平台联系。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉，余干县城投数字服务平台将对申诉进行审查，并自行合理判断决定是否变更处罚措施。</p>
          <p>3、用户理解并同意，余干县城投数字服务平台有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</p>
          <p>4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿余干县城投数字服务平台与合作公司、关联公司，并使之免受损害。</p>
          <p class="title">三、使用规则</p>
          <p>1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表余干县城投数字服务平台的观点、立场或政策，余干县城投数字服务平台对此不承担任何责任。</p>
          <p>2、用户不得利用余干县城投数字服务平台网站账号或本服务进行如下行为：</p>
          <p>(1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；</p>
          <p>(2) 虚构事实、隐瞒真相以误导、欺骗他人的；</p>
          <p>(3) 利用技术手段批量建立虚假账号的；</p>
          <p>(4) 利用余干县城投数字服务平台网站账号或本服务从事任何违法犯罪活动的；</p>
          <p>(5) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</p>
          <p>(6) 其他违反法律法规规定、侵犯其他用户合法权益、干扰余干县城投数字服务平台网站正常运营或余干县城投数字服务平台未明示授权的行为。</p>
          <p>3、用户须对利用余干县城投数字服务平台网站账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责。如因此给余干县城投数字服务平台或第三方造成损害的，用户应当依法予以赔偿。</p>
          <p>
        4、余干县城投数字服务平台提供的服务中可能包括广告，用户同意在使用过程中显示余干县城投数字服务平台和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，余干县城投数字服务平台不承担任何责任。
      </p>
          <p class="title">四、其他</p>
          <p>1、余干县城投数字服务平台郑重提醒用户注意本协议中免除余干县城投数字服务平台责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。</p>
          <p>2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和余干县城投数字服务平台之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交余干县城投数字服务平台住所地有管辖权的人民法院管辖。</p>
          <p>3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
          <p>4、本协议最终解释权归余干县城投数字服务平台所有。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'agree',
  components: {},
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.agree-containter{
  padding: 0 20px;
}
.title {
  font-size: 20px;
  font-weight: bold;
}

p {
  font-size: 16px;
  margin: 0;
  text-indent: 2em;
   line-height: 26px; 
}
</style>
